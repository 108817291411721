import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        slideInNav: false,
    },
    getters: {
        getSlideInNav: state => state.slideInNav
    },
    actions: {
        setSlideInNav({commit}, content) {
            commit('setSlideInNav', content)
        },
    },
    mutations: {
        setSlideInNav(state, content) {
            state.slideInNav = content
        }
    }
    // strict: debug,
    // plugins: debug ? [createLogger()] : []
});