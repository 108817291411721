<template>
  <div id="app">
    <nav :class="{ 'navbar--hidden': !showNavbar }">
        <a @click.stop.prevent="setSlideInNav(true)" class="icon-button menu-button"><i class="material-icons">menu</i></a>
        <!-- <ul>
            <li><a href="/" style="color: white;font-size:14px">www.vilhelmmartinsson.com</a></li>
        </ul> -->

        <!-- <ul>
            <li><a @click.prevent="goTo('hero-section')">Home</a></li>
            <li><a @click.prevent="goTo('about-section')">About</a></li>
            <li><a @click.prevent="goTo('services-section')">Services</a></li>
            <li><a @click.prevent="goTo('technologies-section')">Technologies</a></li>
            <li><a @click.prevent="goTo('projects-section')">Projects</a></li>
            <li><a @click.prevent style="pointer-events: none; cursor: default;">Blog</a></li>
            <li><a @click.prevent="goTo('contact-section')">Contact</a></li>
        </ul> -->
        
    </nav>

    <transition name="slide">
        <div v-if="slideInNav" class="slide-in-nav" v-click-outside="hideNavbar">
            <a class="close" @click.prevent="hideNavbar">&times;</a>
            <ul>
                <li><a @click.prevent="goTo('hero-section')" href="/">Home</a></li>
                <li><a @click.prevent="goTo('about-section')" href="/about">About</a></li>
                <li><a @click.prevent="goTo('services-section')" href="/services">Services</a></li>
                <li><a @click.prevent="goTo('technologies-section')" href="/technologies">Technologies</a></li>
                <li><a @click.prevent="goTo('projects-section')" href="/projects">Projects</a></li>
                <li><a @click.prevent style="pointer-events: none; cursor: default;opacity:0.4">T.V.S</a></li>
                <li><a @click.prevent="goTo('contact-section')" href="/contact">Contact</a></li>
            </ul>
        </div>
    </transition>

    <router-view></router-view>

    <!-- <div class="cookie-policy container" v-if="showPrivacyMessage">
      <div class="column" style="width:100%">
          <p>This website uses cookies to provide you with a better user experience. By using this site, you accept our use of cookies.</p>
      </div>
      <div class="row" style="white-space: nowrap;align-items:center;text-align:right;">
          <a class="ok-button" @click.prevent="hidePrivacyMessage">Ok</a>
          <a class="readmore-button" href="/" @click.prevent="showPrivacy">Read more</a>
      </div>
    </div> -->

  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  name: 'App',
  data() {
      return {
          showPrivacyMessage: true,
          showNavbar: true,
          lastScrollPosition: 0, 
          homeTop: ""
      }
  },
  mounted() {
      window.addEventListener('scroll', this.onScroll)
  },
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Vilhelm Martinsson - www.vilhelmmartinsson.com | Web Applications | Full-stack Web Development | Digital Agency',
      // all titles will be injected into this template
      titleTemplate: '%s | Vilhelm Martinsson - www.vilhelmmartinsson.com | Web Applications | Full-stack Web Development | Digital Agency'
  },
  computed: {
      slideInNav() {
          return this.$store.getters.getSlideInNav
      }
  },
  directives: {
      ClickOutside
  },
  beforeDestroy () {
      window.removeEventListener('scroll', this.onScroll)
  }, 
  methods: {
      onScroll () {
          const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
          if (currentScrollPosition < 0) {
              return
          }
          // Stop executing this function if the difference between
          // current scroll position and last scroll position is less than some offset
          if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
              return
          }
          this.showNavbar = currentScrollPosition < this.lastScrollPosition
          this.lastScrollPosition = currentScrollPosition
      },
      hideNavbar() {
          this.$store.dispatch('setSlideInNav', false)
      },
      goTo(element) {
          const el = document.getElementById(element)

          el.scrollIntoView({
              behavior: "smooth"
          })

          this.hideNavbar()
      }, 
      showPrivacy() {
          this.$modal.show('privacy-modal')
          this.hidePrivacyMessage()
      }, 
      hidePrivacyMessage() {
          this.showPrivacyMessage = false
      },
      setSlideInNav(content) {
          this.$store.dispatch('setSlideInNav', content)
      },
  }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box;
}

html {
    /*font-family: 'Roboto', sans-serif;*/
    font-family: 'Barlow', sans-serif;
    scroll-behavior: smooth;
}

html, body {
    height: 100%;
}

#app {
    height: 100%;
    width: 100%;
}

body {
    width: 100%;
    background: url("./assets/background-mobile.jpg") no-repeat center center; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

section {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*max-width: 916px;*/
    background-color: white;
    margin: 0 auto;
    padding: 8rem;
    text-align: center;
    line-height: 1.6;
    overflow: hidden;
}

section:nth-child(odd) {
    background-color: #f5f5f5;
}

h1, section h2 {
    font-family: 'EB Garamond', serif;
    line-height: 1.2;
    font-size: 2.375em;
    margin-bottom: 2rem;
}

section h3 {
    margin-bottom: 2rem;
}

hr {
    width: 80px;
    height: 3px;
    background-color: #0c5f87;
    border: 0;
    margin: 0 auto;
    margin-bottom: 4rem;
    text-align: center;
}

.container {
    display: flex;
    flex-direction: row;
}

.row {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

p {
    text-align: center;
    max-width: 775px;
    font-size: 18px;
    margin-bottom: 2rem;
}

section p:last-child {
    margin-bottom: 0;
}

nav {
    display: flex;
    align-items: center;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    color: white;
    transform: translate3d(0, 0, 0);
    transition: 0.3s all ease-out;
}

nav.navbar--hidden {
    transform: translate3d(0, -100%, 0);
}

nav ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
    height: 84px;
    /*z-index: 11;*/
    color: white;
}

nav ul li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem;
    height: 100%;
    position: relative;
}

nav ul li a {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 15px;
    display: flex;
    align-items: center;
    font-weight: normal;
    cursor: pointer;
    text-decoration: none;
    opacity: 0.75;
    color: white;
}

nav ul li i {
    font-size: 32px;
}

nav .menu-button i {
    margin: 1rem;
    /*display: none;*/
    cursor: pointer;
}

nav ul li a.active {
    color: white;
    font-weight: bold;
    opacity: 1;
}

nav ul li a:hover {
    font-weight: bold;
    color: white;
    opacity: 1;
}

nav.home-nav ul li a {
    color: black;
}

a {
    text-decoration: none;
}

.close {
    position: absolute;
    top: -6px;
    left: 19px;
    font-size: 54px;
    color: white;
    cursor: pointer;
}

.slide-in-nav {
    position: fixed;
    top: 0;
    height: 100%;
    background-color: #070b17;
    left: 0;
    z-index: 201;
    padding: 3rem;
    box-shadow: 10px 10px 20px -12px rgba(0,0,0,0.75);
    -webkit-box-shadow: 10px 10px 20px -12px rgba(0,0,0,0.75);
    -moz-box-shadow: 10px 10px 20px -12px rgba(0,0,0,0.75); 
    width: 14em;
}

.slide-in-nav ul {
    list-style-type: none;
    margin: 0;
    margin-top: 2rem;
    padding: 0;
    color: white;
}

.slide-in-nav ul li {
    position: relative;
    margin-bottom: 2rem;
}

.slide-in-nav ul li a {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: normal;
    cursor: pointer;
    opacity: 0.75;
    color: white;
}

nav .menu-button i {
    margin: 1rem;
    /*display: none;*/
    cursor: pointer;
}

.slide-in-nav ul li a.active {
    color: white;
    font-weight: bold;
    opacity: 1;
}

.slide-in-nav ul li a:hover {
    font-weight: bold;
    color: white;
}

.slide-in-nav ul li a:hover {
    opacity: 1;
}

.slide-in-nav a {
    color: white;
}

/* Slide effect */

.slide-enter,
.slide-leave-to {
    transform: translateX(-100%);
}

.slide-enter-to,
.slide-leave {
    transform: translateX(0);
}

.slide-enter-active, .slide-leave-active {
    -moz-transition: all 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
}

.cookie-policy {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    z-index: 10000;
    position: fixed;
    bottom: 0;
    background-color: #000;
    padding: 1rem;
    font-size: 14px;
    color: #fff;
}

.cookie-policy .container {
    width: 100%;
}

.cookie-policy p {
    margin-bottom: 0;
    margin-right: 1rem;
    font-size: 14px;
    max-width: none;
    text-align: left;
}

.cookie-policy a { 
    display: inline-block;
    opacity: 0.75;
}

.cookie-policy a:hover, .cookie-policy a:active { 
    opacity: 1;
}

a.ok-button {
    margin-right: 1rem;
    border: none;
    border: 1px solid #e24cc2;
    color: #e24cc2;
    text-decoration: none;
    box-sizing: border-box;
    /*display: block;*/
    font-weight: bold;
    padding: 8px 20px;
    text-align: center;
    border-radius: 5px;
    line-height: 1.4;
    cursor: pointer;
    outline: none;
    background-color: none;
    opacity: 1;
}

.ok-button:hover {
    background-color: #e24cc2;
    border: 1px solid #e24cc2;
    color: white;
}

.ok-button:active {
    opacity: 0.9;
}

.readmore-button {
    color: #fff;
}

@media (min-width: 480px) {

    body {
        background: url("./assets/background.jpg") no-repeat center center fixed; 
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    nav {
      background: url("./assets/background.jpg") no-repeat center center fixed; 
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
}

@media only screen and (max-width: 480px) {

    nav {
      background-color: #070b17;
    }

    .container {
        flex-direction: column;
    }

    .slide-in-nav {
        right: 0;
        height: initial;
        width: 100%;
    }

    .slide-in-nav ul li a {
        font-size: 18px;
    }

    hr {
        margin-bottom: 3rem;
    }

    p {
        margin-bottom: 2rem;
    }

    section h2 {
        font-size: 34px;
    }

    .cookie-policy {
        flex-direction: column;
        padding: 2rem;
    }

    .cookie-policy p {
        font-size: 14px;
        margin-bottom: 1.5rem;
        margin-right: 0;
    }

    /* Slide effect */

    .slide-enter,
    .slide-leave-to {
        transform: translateY(-100%);
    }

    .slide-enter-to,
    .slide-leave {
        transform: translateY(0);
    }

    .slide-enter-active, .slide-leave-active {
        -moz-transition: all 400ms ease-in-out;
        -webkit-transition: all 400ms ease-in-out;
        -o-transition: all 400ms ease-in-out;
        transition: all 400ms ease-in-out;
    }

}

</style>
