import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'
import vmodal from 'vue-js-modal'
import { store } from './store/store'

// Animations
import AOS from 'aos'
import 'aos/dist/aos.css'

import router from './router'
import App from './App.vue'

// Make Axios play nice with Django CSRF
Axios.defaults.xsrfCookieName = 'csrftoken'
Axios.defaults.xsrfHeaderName = 'X-CSRFToken'

Vue.prototype.$http = Axios;
const token = localStorage.getItem('token')

if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = "Token " + token
}

Vue.config.productionTip = false

Vue.use(Vuex, Axios)
Vue.use(vmodal)

new Vue({
    created () {
        AOS.init({ disable: function() {
            var maxWidth = 480;
            return window.innerWidth < maxWidth;
        }})
    },
    store,
    router,
    render: h => h(App)
}).$mount('#app')
