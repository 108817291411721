import Vue from 'vue'
import Router from 'vue-router'
import VueMeta from 'vue-meta'
import Home from '@/components/Home'

Vue.use(Router)
Vue.use(VueMeta)

// const ifNotAuthenticated = (to, from, next) => {
//   if (!store.getters.isLoggedIn) {
//     next()
//     return
//   }
//   next('/')
// }

// const ifAuthenticated = (to, from, next) => {
//   if (store.getters.isLoggedIn) {
//     next()
//     return
//   }
//   next('/')
// }


export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  // scrollBehavior(to, from, savedPosition) {
  //     return {
  //         x: 0,
  //         y: 0
  //     }
  // },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/:notFound(.*)',
      redirect: { 
          name: 'home'
      }
    }
  ]
})
