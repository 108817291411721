<template>
  <div style="height:100%">
    
    <section id="hero-section" class="hero">

        <div class="logo-wrapper">
            <router-link to="/home" tag="a" style="margin:0"><img id="logo" src="../assets/vmwa.png" /></router-link>
        </div>
        <div class="container">
            <div class="column"></div>
            <div class="column">
                <div class="quote" data-aos="fade" data-aos-duration="3000">
                    <h3><span class="quote-marks">“</span>Technology, like art,<br>
                    is a soaring exercise of the human imagination.</h3>
                    <small>- Daniel Bell, American sociologist.</small>
                </div>
            </div>
        </div>
        
    </section>
    
    <section id="home-section" ref="homeSection">
        <h1 data-aos="fade-left">A digital agency for the modern age...</h1>
        <hr>
        <p style="font-size: 20px;font-weight:bold" data-aos="fade-up">
            In Japanese, the word for crisis <strong>危機</strong> (kiki) is composed of the Kanji characters<br><strong>危</strong> (dangerous) and <strong>機</strong> (opportunity).
        </p>
        <p data-aos="fade-up">
          For the past decades we have witnessed an amazing shift to digitalization. New technologies have disrupted industry after industry. The recent pandemic has accelerated this development even further. To keep up with the fast paced and ever changing market, businesses need to embrace these technologies and adapt fast.
        </p>
        <p data-aos="fade-up">
          A modern digital agency should help to not only survive, but thrive, in this landscape.
        </p>
    </section>
    <section class="about" id="about-section">
        <h2 data-aos="fade-left">About</h2>
        <hr>
        <p data-aos="fade-up">
            My name is Vilhelm Martinsson and I'm a freelancing full-stack web developer currently based in Karlstad, Sweden. I build responsive and user-friendly web applications for small and medium sized businesses.
        </p>
        <p data-aos="fade-up">
            By working through partners with other specialties, we can offer a range of digital services.
        </p>
        <p data-aos="fade-up">
            Please contact me if you want to know more.
        </p>
        <a data-aos="fade" class="button" href="mailto:vilhelmmartinsson@gmail.com">Contact</a>
    </section>
    
    <section class="services" id="services-section">
        <h2 data-aos="fade-left">Services</h2>
        <hr>
        <h3 data-aos="fade-up">
            Front-End Development / Back-End Development / Full-Stack Development
        </h3>
        <p data-aos="fade-up">
            Web Design · Web Applications · Minimum Viable Products · Rest-Api · E-Commerce · GIS/Map · UX/UI · Email · SEO · CRUD · Blog · CMS · CRM · Web Scrapers · Command Line Interfaces · Scripts · Data Analysis · Cloud solutions · Hosting · Server Administration
        </p>
        <h3 data-aos="fade-up">
            Audio / Sound / Music
        </h3>
        <p data-aos="fade-up">
            Music Production · Audio Branding · Soundtracks · Mixing · Mastring · Sound Effects · Music Jingles 
        </p>
    </section>

    <section class="technologies" id="technologies-section">
        <h2 data-aos="fade-left">Techonologies</h2>
        <hr>
        <div class="container">
          <div class="column">
            <ul>
                <li data-aos="fade-left" data-aos-offset="10"><a href="https://www.python.org/"><i class="fab fa-python"></i></a></li>
                <li data-aos="fade-left" data-aos-offset="50"><a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"><i class="fab fa-js-square"></i></a></li>
                <li data-aos="fade-left" data-aos-offset="100"><a href="https://www.vuejs.org/"><i class="fab fa-vuejs"></i></a></li>
            </ul>
          </div>
          <div class="column">
            <ul>
                <li data-aos="fade-left" data-aos-offset="150"><a href="https://nodejs.org/en/"><i class="fab fa-node-js"></i></a></li>
                <li data-aos="fade-left" data-aos-offset="200"><a href="https://www.w3.org/html/"><i class="fab fa-html5"></i></a></li>
                <li data-aos="fade-left" data-aos-offset="250"><a href="https://www.w3.org/Style/CSS/Overview.en.html"><i class="fab fa-css3-alt"></i></a></li>
            </ul>
          </div>
        </div>
        <p data-aos="fade-up">
            I work primarily with technologies such as Python (Django and Flask frameworks), Django-Rest, Javascript (ES6+, Node, Vue.js, Nuxt.js), Postegresql, HTML5, CSS3, SQL, JSON, Git/Github, Heroku, Digital Ocean, Stripe, Mailgun, Nginx, Gunicorn, Linux (Ubuntu) etc.
        </p>
    </section>
    
    <section id="projects-section" class="projects">
        <h2 data-aos="fade-left">Projects</h2>
        <hr>
        <div class="column">
            <div class="item column">
                <a data-aos="fade" href="https://www.halsoformedlingen.se">
                    <img src="../assets/halsoformedlingen.jpg" style="height:150px;width:auto">
                </a>
                <p data-aos="fade-up">"Vilhelm has helped us with everything from requirements, design, development and implementation of our web application. The collaboration has been very good from start to finish and the project was delivered according to plan!"
                </p>
                <small data-aos="fade" data-aos-duration="3000"><strong>- Linus Lindeborg, CEO at Hälsoförmedlingen</strong></small>
            </div>

            <!-- <div class="item">
                <a href="https://www.viktorjensen.com">
                    <img src="../assets/viktorjensen.jpg" style="height:65px;width:auto;">
                </a>
            </div> -->
        </div>
    </section>

    <footer>
        <div class="container" id="contact-section">
            <div class="column">
                <ul class="row social">
                    <li><a href="mailto:vilhelmmartinsson@gmail.com"><i class="fa fa-envelope"></i></a></li>
                    <li><a href="https://github.com/VMWA"><i class="fab fa-github"></i></a></li>
                    <li><a href="https://www.linkedin.com/in/vilhelm-martinsson/"><i class="fab fa-linkedin"></i></a></li>
                    <li><a href="https://twitter.com/Vilhelm_M"><i class="fab fa-twitter"></i></a></li>
                </ul>
                <div class="row"><small>Copyright 2021 &#169; Vilhelm Martinsson<span style="margin-left:5px;margin-right:5px">|</span><a href="/" @click.prevent="showPrivacy" style="margin:0">Privacy policy</a></small></div>
            </div>
        </div>
    </footer>

    <modal name="privacy-modal" :height="'80%'" :width="'80%'" :clickToClose="true">
        <app-privacy></app-privacy>
    </modal>
  </div>
</template>

<script>

import Privacy from './Privacy.vue'

export default {
  name: 'Home',
  metaInfo: {
      title: 'Home',
  },
  data() {
      return {
      }
  },
  methods: {
      showPrivacy () {
          this.$modal.show('privacy-modal')
      }
  }, 
  components: {
      'app-privacy': Privacy,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

a {
    text-decoration: none;
    color: white;
}

.button {
    border: none;
    color: white;
    text-decoration: none;
    box-sizing: border-box;
    /*display: block;*/
    font-weight: bold;
    padding: 12px 20px;
    text-align: center;
    border-radius: 5px;
    line-height: 1.4;
    cursor: pointer;
    outline: none;
    background-color: none;
}

.icon-button .material-icons {
    font-size: 1.8em;
}

h1, h2 {
    line-height: 1.2;
}

h3 {
    margin-bottom: 2rem;
}

section.hero {
    min-height: 50%;
    background: transparent;
    /*background: radial-gradient(#ffffff, #00000000);*/
    position: relative;
    color: white;
    padding: 0;
    overflow: visible;
    justify-content: center;
    align-items: center;
}

section.hero .container {
    width: 100%;
    height: 100%;
}

section.hero .column {
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 4rem;
}

section.hero .quote h3 {
    font-size: 22px;
    font-family: 'EB Garamond', serif;
    margin: 0;
    padding: 0;
    font-weight: normal;
    margin-bottom: 1rem;
}

section.hero .quote small {
    display: block;    
    text-align: right;
}

section.hero .quote .quote-marks {
    font-family: "Times New Roman", Times, serif;
    font-size: 80px; 
    line-height: 0; 
    vertical-align: text-bottom;
    margin-right: 8px;
    color: #bce1ff;
}

section .logo-wrapper {
    position: absolute;
    bottom: -70px;
    box-sizing: content-box;
    width: 100%;
    text-align: center;
}

section.about {
    background-color: #092d4ae6;
    color: white;
}

section.about .button {
    border: 1px solid #e24cc2;
    color: #e24cc2;
    margin-top: 1rem;
}

section.about .button:hover {
    background-color: #e24cc2;
    border: 1px solid #e24cc2;
    color: white;
}

section.about .button:active {
    opacity: 0.9;
}

section.about hr {
    background-color: #bce1ff;
}

section.about a i {
    color: white;
    font-size: 1.8em;
}

section.technologies {
    background-color: #01231fde;
    color: white;
}

section.technologies hr {
    background-color: #6fffef;
}

section.technologies .container {
    margin-bottom: 3rem;
}

/*section.technologies ul {
    width: 100%;
}*/

section.technologies ul li {
    display: inline-block;
}

section.technologies ul li a {
    color: white;
    text-decoration: none;
    margin: 1rem;
}

section.technologies ul li i {
    color: white;
    font-size: 1.8em;
}

section.projects .item {
    display: flex;
    justify-content: center;
    align-items: center;
    /*border: 1px solid #cdcdcd;*/
    /*padding: 3rem;*/
    background-color: white;
    color: #353535;
    /*box-shadow: 0 10px 30px rgba(0, 0, 0, .19);*/
    margin-bottom: 4rem;
}

section.projects-section {
    margin: 0;
}

section.projects .item:last-child {
    margin-bottom: 0;
}

section.projects a {
    margin-bottom: 2rem;
}

section.projects p {
    margin-bottom: 1rem;
    font-style: oblique;
}

section.projects img {
    height: 100%;
    max-height: 150px;
    width: auto;
}

footer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #373737;
    color: #fff;
    font-size: 1.000em;
    padding: 4rem;
    text-align: center;    
    line-height: 1.6;
}

footer a i {
    color: white;
    font-size: 1.8em;
}

footer a {
    color: white;
    text-decoration: none;
    margin: 1rem;
}

section h2 {
    font-family: 'EB Garamond', serif;
    font-size: 2.375em;
    margin-bottom: 2rem;
}

ul {
    list-style: none;
}

ul.social {
    margin-bottom: 2rem;
}

ul.social li {
  display: -moz-inline-stack;
  display: inline-block;
}

#logo {
    height: 120px;
    width: 120px;
    border: 5px solid white;
}

.small-logo-container {
    font-size:26px;
}

.small-logo-container a {
    color: white;
    margin-right: 14px;
}

.small-logo-container a:last-child {
    margin-right: 0;
}

#left-logo-text p, #right-logo-text p {
    padding: 0;
    margin: 0;
}

.logo-wrapper:active {
    background: hsla(207, 78%, 16%, 0.9);
}

#middle-content {
    width: 3px;
    background-color: white;
    height: 100%;
}

#right-logo, #left-logo {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;

    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box;
}

#left-logo-text, #right-logo-text {
    z-index: 10;
    opacity: 0;
    position: absolute;

    display: flex;
    flex-direction: column;
    /*height: 100%;
    width: 50%;*/
    text-align: center;
    justify-content: center;
    align-items: center;
    /*display: none;*/
    -webkit-transition: opacity 0.7s;
    -moz-transition: opacity 0.7s;
    transition: opacity 0.7s;
}

.arrow {
    position: absolute;
}


@media only screen and (max-width: 480px) {

    nav {
        padding: 0;
    }

    nav > ul {
        height: 64px;
        display: none;
    }

    nav .menu-button i {
        display: block;
    }

    section {
        padding: 4rem 2rem;
        line-height: 1.4;
    }

    #logo {
        height: 100px;
        width: 100px;
    }

    section.about {
        background-color: #092d4a;
    }

    section.technologies {
        background-color: #022f2a;
    }

    section.hero .logo-wrapper {
        position: absolute;
        bottom: -60px;
        box-sizing: content-box;
    }

    section.hero .column:first-child {
        display: none;
    }

    section.hero .quote .quote-marks {
        font-size: 80px; 
        line-height: 0; 
        vertical-align: text-bottom;
        margin-right: 6px;
        color: #bce1ff;
    }

    section.hero .quote h3 {
        line-height: 1.2;
        font-size: 22px;
        margin-bottom: 1rem;
    }

    section.hero .quote small {
        font-size: 11px;
    }

    section#home-section {
        padding-top: 8rem;
    }

    section.about .button {
        margin-top: 0;
    }

    section.technologies .column:first-child ul {
        margin-bottom: 2rem;
    }

    section.technologies ul li i[data-v-8dc7cce2] {
        font-size: 32px;
    }

    footer small {
        font-size: 10px;
    }
}

</style>
