<template>
  <div>
      <h2>Privacy policy</h2>
      <hr>
      <p>
      </p>
  </div>
</template>

<script>
export default {
  name: 'Cookies',
  metaInfo: {
      title: 'Privacy',
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    div {
        height: 100%;
        overflow: auto;
        padding: 4rem;
    }

    div > h2 {
        margin-bottom: 1rem;
        text-align: center;
    }

    div > hr {
        height: 2px;
        background-color: black;
    }

    @media only screen and (max-width: 480px) {
      div {
          padding: 2rem !important;
      }

      div > h2 {
          margin-bottom: 0.5rem;
          text-align: center;
      }

      div > hr {

      }

    }

</style>
